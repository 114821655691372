import axiosInstance from './AxiosInstance';
import {ProjectDto} from '../models/ProjectDto';

export const ProjectService = {

    getAllProjects: async (): Promise<ProjectDto[]> => {
        const response = await axiosInstance.get<ProjectDto[]>('/project');
        return response.data;
    },

    getProjectById: async (projectId: string): Promise<ProjectDto> => {
        const response = await axiosInstance.get<ProjectDto>(`/project/${projectId}`);
        return response.data;
    },

    updateProject: async (projectId: string, projectData: ProjectDto): Promise<ProjectDto> => {
        const formattedProjectData = {
            ...projectData,
            startedAt: new Date(projectData.startedAt),
            completedAt: new Date(projectData.completedAt),
        };

        const response = await axiosInstance.put<ProjectDto>(`/project/${projectId}`, formattedProjectData);
        return response.data;
    },

    deleteProject: async (projectId: string): Promise<void> => {
        await axiosInstance.delete(`/project/${projectId}`);
    },

    createProject: async (newProjectData: ProjectDto): Promise<ProjectDto> => {
        const formattedProjectData = {
            ...newProjectData,
            startedAt: new Date(newProjectData.startedAt),
            completedAt: new Date(newProjectData.completedAt),
        };

        const response = await axiosInstance.post<ProjectDto>('/project', formattedProjectData);
        return response.data;
    },
};