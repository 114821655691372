import React from 'react';

interface TextInputComponentProps {
    id: string;
    label: string;
    type: string;
    value: any;
    placeholder: any;
    onChange: (value: string) => void;
    min?: number;
    max?: number;
}

const InputComponent: React.FC<TextInputComponentProps> = ({
                                                               id,
                                                               label,
                                                               type,
                                                               value,
                                                               placeholder,
                                                               onChange,
                                                               min = 0,
                                                               max = 100,
                                                           }) => {


    return (
        <>
            {type === 'url' && (
                <>
                    <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                        {label}
                    </label>
                    <div className="mt-2 flex rounded-md shadow-sm mb-8">
                    <span
                        className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                  https://
                    </span>
                        <input
                            type="text"
                            name={id}
                            id={id}
                            value={value}
                            onChange={(e) => onChange("https://" + e.target.value)}
                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder={placeholder}
                        />
                    </div>
                </>
            )}
            {type === 'number' && (
                <>
                    <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                        {label}
                    </label>
                    <div className="mt-2">
                        <input
                            type="number"
                            name={id}
                            id={id}
                            max={max}
                            min={min}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder={placeholder}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                        />
                    </div>
                </>
            )}
            {type !== 'url' && type !== 'number' && (
                <>
                    <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                        {label}
                    </label>
                    <div className="mt-2">
                        <input
                            type={type}
                            name={id}
                            id={id}
                            max="100"
                            min="0"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder={placeholder}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                        />
                    </div>
                </>
            )}
        </>
    )
        ;
};

export default InputComponent;