import {configureStore} from '@reduxjs/toolkit'
import projectReducer from "./ProjectReducer";
import stateService from './StateService';

export const store = configureStore({
    reducer: {
        project: projectReducer
    }
})

store.subscribe(() => {
    const state = store.getState();
    const projectState: any[] = state.project;
    stateService.saveState(projectState);
});