import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import LoginComponent from './components/LoginComponent';
import keycloakService from './services/KeycloakService';
import ProjectDisplayComponent from "./components/ProjectDisplayComponent";
import ProjectFormComponent from "./components/ProjectFormComponent";
import HeaderComponent from "./components/HeaderComponent";
import ProjectListComponent from "./components/ProjectListComponent";
import {useEffect, useState} from "react";
import {Provider} from "react-redux";
import {store} from "./services/store";

const App = () => {


    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<LoginComponent/>}/>
                    <Route path="/projects" element={<ProtectedRoute><ProjectListComponent/></ProtectedRoute>}/>
                    <Route path="/projects/:projectId"
                           element={<ProtectedRoute><ProjectDisplayComponent/></ProtectedRoute>}/>
                    <Route path="/projects/new"
                           element={<ProtectedRoute><ProjectFormComponent isRecover={false}
                                                                          isEdit={false}/></ProtectedRoute>}/>
                    <Route path="/projects/edit/:projectId"
                           element={<ProtectedRoute><ProjectFormComponent isRecover={false}
                                                                          isEdit={true}/></ProtectedRoute>}/>
                    <Route path="/projects/recover/:projectId"
                           element={<ProtectedRoute><ProjectFormComponent isRecover={true}
                                                                          isEdit={true}/></ProtectedRoute>}/>
                    <Route path="/projects/recover/"
                           element={<ProtectedRoute><ProjectFormComponent isRecover={true}
                                                                          isEdit={false}/></ProtectedRoute>}/>
                </Routes>
            </Router>
        </Provider>
    )
        ;
};

const ProtectedRoute = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        keycloakService.initLoginRequired(() => {
            if (!keycloakService.isAuthenticated()) {
                navigate("/");
            } else {
                setIsAuthenticated(true);
            }
        })
    }, [navigate]);

    if (!isAuthenticated) {
        return null;
    }


    return (
        <>
            <HeaderComponent/>
            {children}
        </>
    );
};

export default App;