import {ProjectDto} from "../models/ProjectDto";

export class StateService {
    loadState(): ProjectDto | undefined {
        try {
            const serializedState = localStorage.getItem('state');
            if (!serializedState) return undefined;
            return JSON.parse(serializedState) as ProjectDto;
        } catch (err) {
            console.error('Failed to load state:', err);
            return undefined;
        }
    }

    saveState(state: any): void {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem('state', serializedState);
        } catch (err) {
            console.error('Failed to save state:', err);
        }
    }

    deleteState(): void {
        try {
            localStorage.removeItem("state");
            console.log(`State deleted successfully.`);
        } catch (err) {
            console.error(`Failed to delete state:`, err);
        }
    }
}

const stateService = new StateService();
export default stateService;
