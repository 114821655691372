import React from 'react';
import {SkillWithCountDto} from "../models/SkillWithCountDto";

interface SkillsTableProps {
    skills: SkillWithCountDto[];
}

const SkillsTableComponent: React.FC<SkillsTableProps> = ({skills}) => {

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    function snakeCaseToTitleCase(snakeCaseString: string) {
        return snakeCaseString
            .toLowerCase()
            .replace(/(_\w)/g, match => ` ${match[1].toUpperCase()}`)
            .replace(/^./, match => match.toUpperCase());
    }

    return (
        <>
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                    <th scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Skill Name
                    </th>
                    <th scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Category
                    </th>
                    <th scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Count
                    </th>
                </tr>
                </thead>
                <tbody>
                {skills.map((skill, skillIdx) => (
                    <tr key={skill.name}>
                        <td className={classNames(skillIdx === 0 ? '' : 'border-t border-gray-200', 'relative py-4 pl-4 pr-3 text-sm sm:pl-6')}>
                            <div className="font-medium text-gray-900">{skill.name}</div>
                        </td>
                        <td className={classNames(skillIdx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500')}>
                            {snakeCaseToTitleCase(skill.skillCategory)}
                        </td>
                        <td className={classNames(skillIdx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500')}>
                            {skill.count}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
}

export default SkillsTableComponent;