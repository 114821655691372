import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ProjectDto} from '../models/ProjectDto';
import {ProjectService} from '../services/ProjectService';
import {ExclamationTriangleIcon, PencilIcon, TrashIcon} from "@heroicons/react/20/solid";
import stateService from "../services/StateService";
import ProjectTableComponent from "./ProjectTableComponent";
import {TextDto} from "../models/TextDto";
import {SkillService} from "../services/SkillService";
import SkillsTableComponent from "./SkillsTableComponent";
import {SkillWithCountDto} from "../models/SkillWithCountDto";

const ProjectListComponent: React.FC = () => {
    const [allProjects, setAllProjects] = useState<ProjectDto[]>([]);
    const [projects, setProjects] = useState<ProjectDto[]>([]);
    const [savedProjectName, setSavedProjectName] = useState<string>(null);
    const [loading, setLoading] = useState(true);
    const [skills, setSkills] = useState<SkillWithCountDto[]>(null);

    const [tabs, setTabs] = useState([
        {name: 'All', current: true, count: 0},
        {name: 'Active', current: false, count: 0},
        {name: 'Missing content', current: false, count: 0},
        {name: 'Skills', current: false, count: 0},
    ]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                let data = await ProjectService.getAllProjects();
                await loadSkills();
                const loadedProject = stateService.loadState();
                if (loadedProject) {
                    const persistedProject = data.find(p => p.id === loadedProject.id)
                    if (persistedProject == null) {
                        setSavedProjectName(loadedProject.name)
                    } else {
                        setSavedProjectName(persistedProject.name)
                    }
                }

                data = data.sort((a, b) => a.relevanceScore < b.relevanceScore ? 1 : -1);

                setAllProjects(data);
                setTabCount("All", data.length)
                setTabCount("Active", filterActiveProjects(data).length)
                setTabCount("Missing content", filterProjectsByEmptyDescription(data).length)

                setProjects(data)
            } catch (error) {
                console.error('Error fetching projects:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects().then();
    }, []);

    const handleDeleteProject = async (projectId: string | undefined) => {
        try {
            if (projectId !== undefined && window.confirm("Are you sure you want to delete this project?")) {
                await ProjectService.deleteProject(projectId);
                setProjects(projects.filter(project => project.id !== projectId));
            }
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    const setTabCount = (tabName: string, newCount: number) => {
        setTabs(tabs => tabs.map(tab =>
            tab.name === tabName ? {...tab, count: newCount} : tab
        ));
    };

    const loadSkills = async () => {
        setLoading(true);
        try {
            const data = await SkillService.getAllSkills();
            setSkills(data.sort((a, b) => b.count - a.count));
            setTabCount("Skills", data.length)
        } catch (error) {
            console.error('Fehler beim Abrufen der Skills:', error);
        } finally {
            setLoading(false);
        }
    };

    const filterActiveProjects = (projects: ProjectDto[]): ProjectDto[] => {
        return projects.filter(project => project.active === true);
    };

    function filterProjectsByEmptyDescription(projects: ProjectDto[]): ProjectDto[] {
        const isDescriptionEmpty = (texts: TextDto[] | undefined): boolean => {
            return !texts || texts.length === 0 || texts.some(text => !text.content.trim());
        };

        return projects.filter(project =>
            isDescriptionEmpty(project.shortDescription) ||
            isDescriptionEmpty(project.description) ||
            isDescriptionEmpty(project.goal)
        );
    }

    const handleTabChange = async (name: string) => {
        const updatedTabs = tabs.map(tab => ({
            ...tab,
            current: tab.name === name
        }));

        setTabs(updatedTabs);

        if (name === 'All') {
            setProjects(allProjects);
        } else if (name === 'Active') {
            setProjects(filterActiveProjects(allProjects))
        } else if (name === 'Missing content') {
            setProjects(filterProjectsByEmptyDescription(allProjects));
        } else if (name === 'Skills') {

        }
    }

    const handleDeleteState = async () => {
        stateService.deleteState();
        setSavedProjectName(null)
    };

    const handleEditLocalCopy = async () => {
        const localProject = projects.find(p => p.name === savedProjectName);
        if (localProject != null) {
            navigate("/projects/recover/" + localProject.id)
        } else {
            navigate("/projects/recover/")
        }

    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            {loading ? null : (
                <div className="py-10">
                    <main>
                        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div className="-mx-4 mt-2 sm:-mx-0">
                                    {savedProjectName && (
                                        <div className="flex w-full items-center justify-start text-gray-500 mb-16">
                                            <div
                                                className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="p-4">
                                                    <div className="flex items-center h-full">
                                                        <div className="flex-shrink-0">
                                                            <ExclamationTriangleIcon className="h-6 w-6 text-orange-400"
                                                                                     aria-hidden="true"/>
                                                        </div>
                                                        <div className="ml-6 w-0 flex-1 pt-0.5">
                                                            <p className="text-sm font-medium text-gray-600">A local
                                                                copy of project "{savedProjectName}" is stored.</p>
                                                        </div>
                                                        <div className="ml-4 flex flex-shrink-0">
                                                            <button
                                                                onClick={() => handleEditLocalCopy()}
                                                                type="button"
                                                                className="my-2 ml-2 inline-flex items-center rounded-md text-gray-600 px-3 py-1.5 text-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                <PencilIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </button>
                                                            <button
                                                                onClick={() => handleDeleteState()}
                                                                type="button"
                                                                className="my-2 mr-2 inline-flex items-center rounded-md text-gray-600 px-3 py-1.5 text-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <div className="sm:hidden mt-2">
                                            <label htmlFor="tabs" className="sr-only">
                                                Select a tab
                                            </label>
                                            <select
                                                id="tabs"
                                                name="tabs"
                                                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                defaultValue={tabs.find((tab) => tab.current).name}
                                            >
                                                {tabs.map((tab) => (
                                                    <option key={tab.name}>{tab.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="hidden sm:block">
                                            <div className="border-b border-gray-200">
                                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                                    {tabs.map((tab) => (
                                                        <button
                                                            key={tab.name}
                                                            onClick={() => handleTabChange(tab.name)}
                                                            className={classNames(
                                                                tab.current
                                                                    ? 'border-indigo-500 text-indigo-600'
                                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                                            )}
                                                            aria-current={tab.current ? 'page' : undefined}
                                                        >
                                                            {tab.name}
                                                            {tab.count ? (
                                                                <span
                                                                    className={classNames(
                                                                        tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                                                                        'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                                                                    )}
                                                                >
                    {tab.count}
                  </span>
                                                            ) : null}
                                                        </button>
                                                    ))}
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    {tabs.find(tab => tab.name === 'Skills' && tab.current) ? (
                                        <>
                                            <SkillsTableComponent skills={skills}/>
                                        </>
                                    ) : (
                                        <>
                                            <ProjectTableComponent projects={projects}
                                                                   onDeleteProject={handleDeleteProject}/>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )
            }
        </div>
    );
};

export default ProjectListComponent;





