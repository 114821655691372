import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ProjectDto} from "../models/ProjectDto";
import {ProjectService} from "../services/ProjectService";
import {TextDto} from "../models/TextDto";
import {Language} from "../models/Language";
import {ProjectCategory} from "../models/ProjectCategory";
import {HyperlinkDto} from "../models/HyperlinkDto";
import SelectComponent from "./SelectComponent";
import InputComponent from "./InputComponent";
import {PlusIcon, TrashIcon} from "@heroicons/react/20/solid";
import SwitchComponent from "./SwitchComponent";
import {SkillCategory} from "../models/SkillCategory";
import {SkillDto} from "../models/SkillDto";
import {Role} from "../models/Role";
import stateService from "../services/StateService";
import {ProjectType} from "../models/ProjectType";

interface RouteParams {
    projectId: string;

    [key: string]: string | undefined;
}

const ProjectFormComponent = ({
                                  isRecover,
                                  isEdit
                              }
) => {

    const {projectId} = useParams<RouteParams>();
    const navigate = useNavigate();
    const [project, setProject] = useState<ProjectDto | null>(null);
    const [loading, setLoading] = useState(true);


    const initialProjectData = () => ({
        id: null,
        name: '',
        shortDescription: [{content: '', language: Language.ENGLISH}, {content: '', language: Language.GERMAN}],
        description: [{content: '', language: Language.ENGLISH}, {content: '', language: Language.GERMAN}],
        goal: [{content: '', language: Language.ENGLISH}, {content: '', language: Language.GERMAN}],
        workDays: 0,
        skills: null,
        category: ProjectCategory.SOFTWARE_DEVELOPMENT,
        type: ProjectType.PRIVATE,
        relevanceScore: 0,
        client: '',
        startedAt: null,
        completedAt: null,
        links: null,
        volume: "",
        roles: [Role.PROJECT_MANAGER],
        language: Language.ENGLISH,
        teamSize: 0,
        completionPercent: 0,
        reviewed: false,
        active: false,
        cv: false,
        createdAt: null,
        updatedAt: null
    });

    const loadProject = useCallback(
        async (id: string) => {
            setLoading(true);
            try {
                const data = await ProjectService.getProjectById(id);
                if (!data.shortDescription || data.shortDescription.length === 0) {
                    data.shortDescription = [{language: Language.ENGLISH, content: ''}];
                }
                if (!data.description || data.description.length === 0) {
                    data.description = [{language: Language.ENGLISH, content: ''}];
                }
                if (!data.goal || data.goal.length === 0) {
                    data.goal = [{language: Language.ENGLISH, content: ''}];
                }
                if (data.language == null) {
                    data.language = Language.ENGLISH
                }
                if (data.category == null) {
                    data.category = ProjectCategory.SOFTWARE_DEVELOPMENT
                }
                if (data.type == null) {
                    data.type = ProjectType.PRIVATE
                }
                if (data.volume == null) {
                    data.volume = ""
                }

                if (data.teamSize == null) {
                    data.teamSize = 0
                }
                if (data.completionPercent == null) {
                    data.completionPercent = 0
                }

                setProject(() => data);

            } catch (error) {
                navigate("/project")
            }
        }, [navigate]);

    useEffect(() => {

        if (isRecover && projectId) {
            console.log("recover with already saved project")
            setProject(stateService.loadState())
            setLoading(false);
            return;
        }

        if (isRecover && !projectId) {
            console.log("recover with new project")
            setProject(stateService.loadState())
            setLoading(false);
            return;
        }

        if (isEdit) {
            console.log("edit project")
            loadProject(projectId).then(() => setLoading(false));
            return;
        }

        if (!isEdit) {
            console.log("new project")
            setProject(initialProjectData)
            setLoading(false);
            return;
        }

    }, [loadProject, projectId, isEdit, isRecover]);

    useEffect(() => {
        stateService.saveState(project);
    }, [project]);


    const handleTextChange = (key: 'shortDescription' | 'description' | 'goal', index: number, text: TextDto) => {
        const updatedTexts = [...project[key]];
        updatedTexts[index] = text;
        setProject({...project, [key]: updatedTexts});
    };

    const handleAddText = (key: 'shortDescription' | 'description' | 'goal') => {
        const newText = {language: Language.GERMAN, content: ''};
        setProject({...project, [key]: [...project[key], newText]});
    };

    const handleRemoveText = (key: 'shortDescription' | 'description' | 'goal', index: number) => {
        const filteredTexts = project[key].filter((_, i) => i !== index);
        setProject({...project, [key]: filteredTexts});
    };

    const handleAddSkill = () => {
        const newSkill = {name: '', skillCategory: SkillCategory.PROGRAMMING_LANGUAGE};
        if (project.skills === null) {
            setProject({...project, skills: [newSkill]});
        } else {
            setProject({...project, skills: [...project['skills'], newSkill]});
        }
    };

    const handleSkillChange = (index: number, skillDto: SkillDto) => {
        const updatedSkills = [...project['skills']];
        updatedSkills[index] = skillDto;
        setProject({...project, skills: updatedSkills});
    };

    const handleRemoveSkill = (index: number) => {
        const filteredSkills = project['skills'].filter((_, i) => i !== index);
        setProject({...project, skills: filteredSkills});
    };

    const handleAddLink = () => {
        const newHyperlink = {url: '', description: '', active: false};
        if (project.links === null) {
            setProject({...project, links: [newHyperlink]});
        } else {
            setProject({...project, links: [...project['links'], newHyperlink]});
        }
    };

    const handleLinkChange = (index: number, hyperlinkDto: HyperlinkDto) => {
        const updatedLinks = [...project['links']];
        updatedLinks[index] = hyperlinkDto;
        setProject({...project, links: updatedLinks});
    };

    const handleRemoveLink = (index: number) => {
        const filteredHyperlinks = project['links'].filter((_, i) => i !== index);
        setProject({...project, links: filteredHyperlinks});
    };

    const handleAddRole = () => {
        const newRole = Role.PROJECT_MANAGER;
        if (project.roles === null) {
            setProject({...project, roles: [newRole]});
        } else {
            setProject({...project, roles: [...project['roles'], newRole]});
        }
    };

    const handleRoleChange = (index: number, role: Role) => {
        const updatedRoles = [...project['roles']];
        updatedRoles[index] = role;
        setProject({...project, roles: updatedRoles});
    };

    const handleRemoveRole = (index: number) => {
        const filteredRoles = project['roles'].filter((_, i) => i !== index);
        setProject({...project, roles: filteredRoles});
    };

    const handleInputChange = (key: any, value: any) => {
        setProject({...project, [key]: value});
    };

    const handleUpdate = async () => {
        try {
            await ProjectService.updateProject(projectId, project).then(() => {
                navigate('/projects')
                stateService.deleteState();
            })
        } catch (error) {
            navigate("/projects")
        }
    };

    const handleCreate = async () => {
        try {
            await ProjectService.createProject(project).then(() => {
                stateService.deleteState();
                navigate('/projects');
            })
        } catch (error) {
            navigate("/projects")
        }
    };

    const handleCancel = async () => {
        stateService.deleteState();
        navigate("/projects")
    };

    function enumToOptions<T>(e: T): Record<string, string> {
        return Object.entries(e).reduce((acc, [key, value]) => {
            if (!isNaN(Number(key))) return acc;
            return {...acc, [key]: value};
        }, {});
    }

    return (
        <div>
            {loading ? (
                <></>
            ) : (
                <div className="py-10">
                    <header>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{isEdit ? 'Edit ' : 'New '}
                                Project {isEdit ? '"' + project.name + '"' : ''}</h1>
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto mt-10 max-w-7xl sm:px-6 lg:px-8">
                            <div className="space-y-12">
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">General Project
                                            Information</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"projectName"} label={"Project Name"} type={"text"}
                                                            value={project.name} placeholder={"Name of Project"}
                                                            onChange={(value: string) => handleInputChange('name', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"client"} label={"Client"} type={"text"}
                                                            value={project.client}
                                                            placeholder={"Client"}
                                                            onChange={(value: string) => handleInputChange('client', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <SelectComponent id={"type"} label={"Project Type"}
                                                             options={enumToOptions(ProjectType)}
                                                             selectedValue={project.type}
                                                             onChange={(value: string) => handleInputChange('type', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <SelectComponent id={"category"} label={"Project Category"}
                                                             options={enumToOptions(ProjectCategory)}
                                                             selectedValue={project.category}
                                                             onChange={(value: string) => handleInputChange('category', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <SelectComponent id={"projectLanguage"} label={"Language"}
                                                             options={enumToOptions(Language)}
                                                             selectedValue={project.language}
                                                             onChange={(value: string) => handleInputChange('language', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <h3 className="block text-sm font-medium leading-6 text-gray-900">
                                                Roles
                                            </h3>
                                            {project.roles !== null && project.roles.map((role, index) => (
                                                <div key={index}>
                                                    <div className="flex justify-start">
                                                        <SelectComponent id={"role" + index}
                                                                         label={""}
                                                                         options={enumToOptions(Role)}
                                                                         selectedValue={role}
                                                                         onChange={(value: string) => handleRoleChange(index, value as Role)}/>
                                                        <button
                                                            onClick={() => handleRemoveRole(index)}
                                                            type="button"
                                                            className="my-2 ml-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </button>
                                                        {index === project.roles.length - 1 && index < Object.keys(Role).length && (
                                                            <button
                                                                onClick={() => handleAddRole()}
                                                                type="button"
                                                                className="my-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            {project.roles.length === 0 && (
                                                <button
                                                    onClick={() => handleAddRole()}
                                                    type="button"
                                                    className="my-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Resources
                                            Information</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"workdays"} label={"Workdays"} type={"number"}
                                                            max={10000} value={project.workDays}
                                                            placeholder={"Workdays"}
                                                            onChange={(value: string) => handleInputChange('workDays', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"volume"} label={"Project Volume"} type={"text"}
                                                            value={project.volume} placeholder={"Project Volume"}
                                                            onChange={(value: string) => handleInputChange('volume', value)}></InputComponent>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"teamSize"} label={"Team Size"} type={"number"}
                                                            value={project.teamSize} placeholder={"Size of Team"}
                                                            onChange={(value: string) => handleInputChange('teamSize', value)}></InputComponent>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Dates and
                                            Progress</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"startedAt"} label={"Start of Project"} type={"date"}
                                                            value={project.startedAt ? new Date(project.startedAt).toISOString().split('T')[0] : ''}
                                                            placeholder={"Start of Project"}
                                                            onChange={(value) => handleInputChange('startedAt', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"completedAt"} label={"End of Project"} type={"date"}
                                                            value={project.completedAt ? new Date(project.completedAt).toISOString().split('T')[0] : ''}
                                                            placeholder={"End of Project"}
                                                            onChange={(value) => handleInputChange('completedAt', value)}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <InputComponent id={"completionPercent"} label={"Percent Completed"}
                                                            type={"number"}
                                                            value={project.completionPercent}
                                                            placeholder={"Completion Rate in %"}
                                                            onChange={(value) => handleInputChange('completionPercent', value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Settings</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            <SwitchComponent flag={project.active} label={"Project Active"}
                                                             onChange={(flag: boolean) => setProject({
                                                                 ...project,
                                                                 active: flag
                                                             })}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <SwitchComponent flag={project.reviewed} label={"Project Reviewed"}
                                                             onChange={(flag: boolean) => setProject({
                                                                 ...project,
                                                                 reviewed: flag
                                                             })}/>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <SwitchComponent flag={project.cv} label={"Show on CV"}
                                                             onChange={(flag: boolean) => setProject({
                                                                 ...project,
                                                                 cv: flag
                                                             })}/>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Hyperlinks</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            {project.links !== null && project.links.map((link, index) => (
                                                <div key={index}
                                                     className={index !== project.links.length - 1 ? "mb-12" : ""}>
                                                    <InputComponent id={"url" + index} label={"Url"} type={"url"}
                                                                    value={link.url.replace(/^https:\/\//, '')}
                                                                    placeholder={"Url"}
                                                                    onChange={(value: string) => handleLinkChange(index, {
                                                                        ...link,
                                                                        url: value
                                                                    })}/>
                                                    <InputComponent id={"description" + index} label={"Description"}
                                                                    type={"text"}
                                                                    value={link.description} placeholder={"Description"}
                                                                    onChange={(value: string) => handleLinkChange(index, {
                                                                        ...link,
                                                                        description: value
                                                                    })}/>
                                                    <div className="mt-6 flex justify-between">
                                                        <SwitchComponent flag={link.active} label={"Hyperlink Active"}
                                                                         onChange={(flag: boolean) => handleLinkChange(index, {
                                                                             ...link,
                                                                             active: flag
                                                                         })}/>
                                                        <button
                                                            onClick={() => handleRemoveLink(index)}
                                                            type="button"
                                                            className="my-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </button>
                                                    </div>
                                                    {index !== project.links.length - 1 && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {index === project.links.length - 1 && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleAddLink()}
                                                                    className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                >
                                                                    <PlusIcon
                                                                        className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"/>
                                                                    Add Hyperlink
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            {(!project.links || project.links.length === 0) && (
                                                <div className="relative mt-4">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleAddLink()}
                                                            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                        >
                                                            <PlusIcon
                                                                className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                                                aria-hidden="true"/>
                                                            Add Hyperlink
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Skills</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            {project.skills !== null && project.skills.map((skill, index) => (
                                                <div key={index}
                                                     className={index !== project.skills.length - 1 ? "mb-12" : ""}>
                                                    <InputComponent id={"skillName" + index} label={"Skill Name"}
                                                                    type={"text"}
                                                                    value={skill.name} placeholder={"Skill Name"}
                                                                    onChange={(value: string) => handleSkillChange(index, {
                                                                        ...skill,
                                                                        name: value
                                                                    })}/>
                                                    <div className="mt-2 flex justify-end">
                                                        <button
                                                            onClick={() => handleRemoveSkill(index)}
                                                            type="button"
                                                            className="my-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                        >
                                                            <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                                        </button>
                                                        <SelectComponent id={"skillCategory" + index}
                                                                         label={""}
                                                                         options={enumToOptions(SkillCategory)}
                                                                         selectedValue={skill.skillCategory}
                                                                         onChange={(value: string) => handleSkillChange(index, {
                                                                             ...skill,
                                                                             skillCategory: value as SkillCategory
                                                                         })}/>
                                                    </div>

                                                    {index !== project.skills.length - 1 && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(index === project.skills.length - 1) && (
                                                        <div className="relative mt-6">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleAddSkill()}
                                                                    className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                >
                                                                    <PlusIcon
                                                                        className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"/>
                                                                    Add Skill
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            {(!project.skills || project.skills.length === 0) && (
                                                <div className="relative mt-6">
                                                    <div className="absolute inset-0 flex items-center"
                                                         aria-hidden="true">
                                                        <div className="w-full border-t border-gray-300"/>
                                                    </div>
                                                    <div className="relative flex justify-center">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleAddSkill()}
                                                            className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                        >
                                                            <PlusIcon
                                                                className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                                                aria-hidden="true"/>
                                                            Add Skill
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Short
                                            Description</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            {project.shortDescription.map((desc, index) => (
                                                <div key={index}
                                                     className={index !== project.shortDescription.length - 1 ? "mb-12" : ""}>
                                                    <textarea
                                                        rows={2}
                                                        id={"shortDescriptionTextarea" + index}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        value={desc.content}
                                                        onChange={(e) => handleTextChange("shortDescription", index, {
                                                            ...desc,
                                                            content: e.target.value
                                                        })}
                                                    />
                                                    <div className="mt-2 flex justify-end">
                                                        {index !== 0 && (
                                                            <button
                                                                onClick={() => handleRemoveText("shortDescription", index)}
                                                                type="button"
                                                                className="my-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </button>

                                                        )}
                                                        <SelectComponent id={"shortDescriptionLanguage" + index}
                                                                         label={""}
                                                                         options={enumToOptions(Language)}
                                                                         selectedValue={desc.language}
                                                                         onChange={(value) => handleTextChange("shortDescription", index, {
                                                                             ...desc,
                                                                             language: value as Language
                                                                         })}/>
                                                    </div>
                                                    {index !== project.shortDescription.length - 1 && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {index === project.shortDescription.length - 1 && project.shortDescription.length < Object.keys(Language).length && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleAddText("shortDescription")}
                                                                    className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                >
                                                                    <PlusIcon
                                                                        className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"/>
                                                                    Add Language
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}</div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Goals</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            {project.goal.map((goal, index) => (
                                                <div key={index}
                                                     className={index !== project.goal.length - 1 ? "mb-12" : ""}>
                                                    <textarea
                                                        rows={6}
                                                        id={"goalTextarea" + index}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        value={goal.content}
                                                        onChange={(e) => handleTextChange("goal", index, {
                                                            ...goal,
                                                            content: e.target.value
                                                        })}
                                                    />
                                                    <div className="mt-2 flex justify-end">
                                                        {index !== 0 && (
                                                            <button
                                                                onClick={() => handleRemoveText("goal", index)}
                                                                type="button"
                                                                className="my-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </button>

                                                        )}
                                                        <SelectComponent id={"goalLanguage" + index}
                                                                         label={""}
                                                                         options={enumToOptions(Language)}
                                                                         selectedValue={goal.language}
                                                                         onChange={(value) => handleTextChange("goal", index, {
                                                                             ...goal,
                                                                             language: value as Language
                                                                         })}/>
                                                    </div>
                                                    {index !== project.goal.length - 1 && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {index === project.goal.length - 1 && project.goal.length < Object.keys(Language).length && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleAddText("goal")}
                                                                    className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                >
                                                                    <PlusIcon
                                                                        className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"/>
                                                                    Add Language
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}</div>
                                    </div>
                                </div>
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                    <div>
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Additional
                                            Text</h2>
                                    </div>
                                    <div
                                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                        <div className="sm:col-span-6">
                                            {project.description.map((desc, index) => (
                                                <div key={index}
                                                     className={index !== project.shortDescription.length - 1 ? "mb-12" : ""}>
                                                    <textarea
                                                        rows={6}
                                                        id={"descriptionTextbox" + index}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        value={desc.content}
                                                        onChange={(e) => handleTextChange("description", index, {
                                                            ...desc,
                                                            content: e.target.value
                                                        })}
                                                    />

                                                    <div className="mt-2 flex justify-end">
                                                        {index !== 0 && (
                                                            <button
                                                                onClick={() => handleRemoveText("description", index)}
                                                                type="button"
                                                                className="my-2 mr-2 inline-flex items-center ring-1 ring-inset ring-gray-300 rounded-md text-gray-900 px-3 py-1.5 text-sm shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </button>
                                                        )}
                                                        <SelectComponent id={"descriptionLanguage" + index}
                                                                         label={""}
                                                                         options={enumToOptions(Language)}
                                                                         selectedValue={desc.language}
                                                                         onChange={(value: string) => handleTextChange("description", index, {
                                                                             ...desc,
                                                                             language: value as Language
                                                                         })}/>

                                                    </div>
                                                    {index !== project.description.length - 1 && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {index === project.description.length - 1 && project.description.length < Object.keys(Language).length && (
                                                        <div className="relative mt-4">
                                                            <div className="absolute inset-0 flex items-center"
                                                                 aria-hidden="true">
                                                                <div className="w-full border-t border-gray-300"/>
                                                            </div>
                                                            <div className="relative flex justify-center">

                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleAddText("description")}
                                                                    className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                >
                                                                    <PlusIcon
                                                                        className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400"
                                                                        aria-hidden="true"/>
                                                                    Add Language
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 flex items-center justify-end">
                                    <button type="button" onClick={() => handleCancel()}
                                            className="text-sm font-semibold leading-6 text-gray-900 me-6">
                                        Cancel
                                    </button>
                                    <button onClick={() => isEdit ? handleUpdate() : handleCreate()}
                                            className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{isEdit ? 'Save' : 'Create'}</button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </div>
    )
        ;
};

export default ProjectFormComponent;