import {createSlice} from '@reduxjs/toolkit'

const projectReducer = createSlice({
    name: 'project',
    initialState: [],
    reducers: {
        todoAdded(state, action) {
            state.push({
                id: action.payload.id,
                text: action.payload.text,
                completed: false
            })
        },
        todoToggled(state, action) {
            const todo = state.find(todo => todo.id === action.payload)
            todo.completed = !todo.completed
        }
    }
})

export const {todoAdded, todoToggled} = projectReducer.actions
export default projectReducer.reducer