export enum ProjectCategory {
    WEB_DEVELOPMENT = "WEB_DEVELOPMENT",
    UPDATE = "UPDATE",
    DATA_MIGRATION = "DATA_MIGRATION",
    SYSTEM_CONVERSION = "SYSTEM_CONVERSION",
    CLOUD_INTEGRATION = "CLOUD_INTEGRATION",
    CODE_REMEDIATION = "CODE_REMEDIATION",
    PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
    LOW_LEVEL_DEVELOPMENT = "LOW_LEVEL_DEVELOPMENT",
    SOFTWARE_DEVELOPMENT = "SOFTWARE_DEVELOPMENT",
    SERVER_ARCHITECTURE = "SERVER_ARCHITECTURE",
    UI_UX_DESIGN = "UI_UX_DESIGN",
    PARALLEL_COMPUTING = "PARALLEL_COMPUTING",
    EMBEDDED_SYSTEMS = "EMBEDDED_SYSTEMS",
    NETWORK_CONFIGURATION = "NETWORK_CONFIGURATION",
    DATA_ANALYTICS = "DATA_ANALYTICS",
    PERFORMANCE_OPTIMIZATION = "PERFORMANCE_OPTIMIZATION",
    SECURITY_ENHANCEMENT = "SECURITY_ENHANCEMENT",
    AUTOMATION_SCRIPTING = "AUTOMATION_SCRIPTING",
    DEVOPS_IMPLEMENTATION = "DEVOPS_IMPLEMENTATION",
    CONTENT_MANAGEMENT_SYSTEM = "CONTENT_MANAGEMENT_SYSTEM",
    TESTING_AND_QA = "TESTING_AND_QA"
}
