import React from 'react';

interface SelectComponentProps {
    id: string;
    label: string;
    options: Record<string, string>;
    selectedValue: string;
    onChange: (value: string) => void;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
                                                             id,
                                                             label,
                                                             options,
                                                             selectedValue,
                                                             onChange
                                                         }) => {

    function snakeCaseToTitleCase(snakeCaseString: string) {
        return snakeCaseString
            .toLowerCase()
            .replace(/(_\w)/g, match => ` ${match[1].toUpperCase()}`)
            .replace(/^./, match => match.toUpperCase());
    }

    return (
        <>
            <div>
                <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                    {label}
                </label>
                <select
                    id={id}
                    name={id}
                    className="my-2 block w-fit rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedValue}
                    onChange={(e) => onChange(e.target.value)}
                >
                    {Object.entries(options).map(([key, value]) => (
                        <option key={key} value={key}>{snakeCaseToTitleCase(value)}</option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default SelectComponent;