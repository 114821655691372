import React from 'react';
import {useParams} from 'react-router-dom';

interface RouteParams {
    projectId: string;

    [key: string]: string | undefined;
}

const ProjectDisplayComponent: React.FC = () => {
    const {projectId} = useParams<RouteParams>();
    return <div>Anzeige des Projekts mit der ID: {projectId}</div>;
};

export default ProjectDisplayComponent;