import {Switch} from '@headlessui/react';

interface TextInputComponentProps {
    flag: boolean;
    label: string;
    onChange: (value: boolean) => void;
}

const InputComponent: React.FC<TextInputComponentProps> = ({
                                                               flag,
                                                               label,
                                                               onChange
                                                           }) => {

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <>
            <Switch.Group as="div" className="flex items-center">
                <Switch
                    checked={flag || false}
                    onChange={() => onChange(!flag)}
                    className={classNames(
                        flag ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                    )}
                >
        <span
            aria-hidden="true"
            className={classNames(
                flag ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
        />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                    <span className="font-medium text-gray-900">{label}</span>{' '}
                </Switch.Label>
            </Switch.Group>
        </>
    );
};

export default InputComponent;