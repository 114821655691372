import axiosInstance from './AxiosInstance';
import {SkillWithCountDto} from "../models/SkillWithCountDto";

export const SkillService = {

    getAllSkills: async (): Promise<SkillWithCountDto[]> => {
        const response = await axiosInstance.get<SkillWithCountDto[]>('/skill');
        return response.data;
    },

};