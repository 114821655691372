import Keycloak from 'keycloak-js';

class KeycloakService {
    keycloak: any;
    instantiated: boolean = false;

    constructor() {
        this.keycloak = new Keycloak({
            url: 'https://auth.hagn.network',
            realm: 'home-identity-provider',
            clientId: 'project-management-ui',
        });
    }

    initLoginRequired(onInitCallback: any) {
        if (!this.instantiated) {
            this.keycloak.init({
                onLoad: 'login-required',
                KeycloakResponseType: 'code',
                checkLoginIframe: false,
                pkceMethod: 'S256'
            })
                .then(() => {
                    onInitCallback();
                }).catch(console.error);
        }
        this.instantiated = true;

        this.keycloak.onTokenExpired = () => {
            this.keycloak.updateToken(30);
        }

    }

    logout(): void {
        this.keycloak.logout({redirectUri: "https://projects.hagn.network"});
    }

    isAuthenticated(): boolean {
        return this.keycloak.authenticated;
    }

}

export default new KeycloakService();