import {Link, useNavigate} from 'react-router-dom';
import {ProjectDto} from '../models/ProjectDto';
import {PencilIcon, TrashIcon} from "@heroicons/react/20/solid";
import React from "react";

interface ProjectsTableProps {
    projects: ProjectDto[];
    onDeleteProject: (projectId: string | undefined) => void;
}

const ProjectTableComponent: React.FC<ProjectsTableProps> = ({projects, onDeleteProject}) => {

    const navigate = useNavigate();


    function snakeCaseToTitleCase(snakeCaseString: string) {
        return snakeCaseString
            .toLowerCase()
            .replace(/(_\w)/g, match => ` ${match[1].toUpperCase()}`)
            .replace(/^./, match => match.toUpperCase());
    }

    return (
        <div>
            {projects.length === 0 ? (
                    <p>There are no projects yet :(</p>
                ) :
                (
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                            <th scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                Project Name
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Workdays
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                            >
                                Category
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Score
                            </th>
                            <th scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                Client
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Active
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Reviewed
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                CV
                            </th>
                            <th scope="col"
                                className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {projects.map((project) => (
                            <tr key={project.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {project.name}
                                </td>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {project.workDays}
                                </td>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                    {snakeCaseToTitleCase(project.category)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.relevanceScore}
                                </td>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                    {project.client}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.active ? 'Yes' : 'No'}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.reviewed ? 'Yes' : 'No'}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {project.cv ? 'Yes' : 'No'}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-0">
                                    {/*
                                    <Link to={"/projects/" + project.id}>
                                        <button
                                            className="rounded-full bg-indigo-600 mr-2 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                            <EyeIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                    </Link>*/}
                                    <Link to={"/projects/edit/" + project.id}>
                                        <button
                                            className="rounded-full mr-2 p-2 shadow shadow-gray-300 hover:shadow-inner hover:shadow-blue-700 text-blue-700 focus-visible:outline focus-visible:outline-2 outline-white focus-visible:outline-offset-2 focus-visible:outline-white">
                                            <PencilIcon className="h-5 w-5" aria-hidden="true"/>
                                        </button>
                                    </Link>
                                    <button onClick={() => onDeleteProject(project.id)}
                                            className="rounded-full mr-2 p-2 shadow shadow-gray-300 hover:shadow-inner hover:shadow-blue-700 text-blue-700 focus-visible:outline focus-visible:outline-2 outline-white focus-visible:outline-offset-2 focus-visible:outline-white">
                                        <TrashIcon className="h-5 w-5" aria-hidden="true"/>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
        </div>
    );
};

export default ProjectTableComponent;





