import React from 'react';
import keycloakService from '../services/KeycloakService';
import {Disclosure} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {Link, useLocation, useNavigate} from "react-router-dom";

const HeaderComponent: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const navigation = [
        {name: 'Dashboard', href: '/projects', current: true},
        {name: 'New Project', href: '/projects/new', current: false},
    ]

    const updatedNavigation = navigation.map(item => ({
        ...item,
        current: item.href === location.pathname
    }));

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const handleLogout = () => {
        keycloakService.logout();
        navigate(`/`);
    };

    return (
        <>
            <div className="min-h-full">
                <Disclosure as="nav" className="bg-white shadow-sm">
                    {({open}) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 justify-between">
                                    <div className="flex">
                                        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                            {updatedNavigation.map((item) => (
                                                <Link to={item.href}
                                                      key={item.name}
                                                      className={classNames(
                                                          item.current
                                                              ? 'border-indigo-500 text-gray-900'
                                                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                                                      )}
                                                      aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                        <button
                                            onClick={() => handleLogout()}
                                            type="button"
                                            className="relative rounded-full bg-white p-1 text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            <span className="absolute -inset-1.5"/>
                                            <span className="sr-only">View notifications</span>
                                            Logout
                                        </button>
                                    </div>
                                    <div className="-mr-2 flex items-center sm:hidden">
                                        <Disclosure.Button
                                            className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <span className="absolute -inset-0.5"/>
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                </Disclosure>
            </div>
        </>
    );
}

export default HeaderComponent;