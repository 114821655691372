import React from 'react';
import {useNavigate} from 'react-router-dom';


const LoginComponent = () => {
    const navigate = useNavigate();
    const handleLogin = () => {
        navigate("/projects")
    };

    return (
        <div className="bg-white">
            <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
                <div
                    className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
                    <div className="px-6 lg:px-0 lg:pt-4">
                        <div className="mx-auto max-w-2xl">
                            <div className="max-w-lg">
                                <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                    Your Personal Project Portfolio Assistant
                                </h1>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    PortfolioStream revolutionizes the way you manage and showcase your personal
                                    projects. Designed with professionals and creatives in mind, this sleek web
                                    application allows you to organize, track, and display your projects with ease.
                                    Whether you're a developer, designer, or any professional looking to enhance your
                                    resume, PortfolioStream offers an intuitive platform to compile and present your
                                    work. It's more than just a project list; it's a dynamic space where your
                                    achievements come to life, ready to impress potential employers or clients. Embrace
                                    the simplicity and elegance of PortfolioStream, where your projects tell your story.
                                </p>
                                <div className="mt-10 flex items-center gap-x-6">
                                    <a
                                        onClick={() => handleLogin()}
                                        href="#"
                                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Login
                                    </a>
                                    <a target={"_blank"} href="https://github.com/maxhagn"
                                       className="text-sm font-semibold leading-6 text-gray-900">
                                        View Code on GitHub <span aria-hidden="true">→</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen items-center flex">
                        <img src="hero.png" alt={"Title Picture"}/>
                    </div>
                </div>
                <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"/>
            </div>
        </div>
    );
};

export default LoginComponent;